import { socket } from '@/socket';
import { getItem, removeItem, setItem } from '@/tools';
import { Collapse, message } from 'antd';
import axios from 'axios';
import { t } from 'i18next';
const { Panel } = Collapse;

// console.log("env===", process.env);
//获取环境变量(看属于哪种部署环境) START || DEV || STAG || PROD
const envType = process.env['REACT_APP_ENV'];

const instance = axios.create({
  baseURL: process.env.NODE_ENV === 'production' ? process.env[envType].url1 : ''
});

//请求拦截
instance.interceptors.request.use(
  (config) => {
    const token = getItem('token');
    config.headers['token'] = token;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

//响应拦截
instance.interceptors.response.use(
  (res) => {
    // console.log('res==', res);
    //所有数据状态码 200 201 401 402 403 900
    if (res.status >= 200 && res.status < 300) {
      //1.页面请求资源接口,用户没有登录 2.token已过期
      if (res.data.code === 401) {
        socket?.close();
        removeItem('token');
        removeItem('userId');
        return (window.location.href = '#/login');
      }

      //403 没有权限跳转首页
      if (res.data.code === 403) {
        return (window.location.href = '#/');
      }

      //弹出错误信息
      if (res.data.code === 201 || res.data.code === 900) {
        //修改密码，旧密码不正确，文案提示翻译
        if (res.data?.msg?.includes('The origin password you entered is incorrect')) {
          return message.error(t('changePassword.oldIncorrect'));
        }

        return message.error(
          <span>
            <span>System error</span>
            <Collapse ghost>
              <Panel header={<div style={{ textAlign: 'left' }}>Error details</div>} key="1">
                <p>{res?.data?.msg}</p>
              </Panel>
            </Collapse>
          </span>
        );

        // return message.error(res?.data?.msg);
      }

      //1.登录成功 2.token即将过期续时  获取token，并保存
      const token = res.headers['token'];
      if (token) {
        setItem('token', token);
      }

      return res.data;
    }
    return Promise.reject(res.statusText);
  },
  (error) => {
    // console.log('error==', error);
    if ('message' in error) {
      message.error(error.message);
    }

    return Promise.reject(error);
  }
);

export default instance;
