import { makeAutoObservable, runInAction } from 'mobx';

import { getAuditLog, getAuditLogTypes } from '@/api';

class OperationHistory {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  auditLogList = [];
  total = 0;
  current = 1;
  pageSize = 10;
  tableLoading = false;
  auditLogTypes = [];

  //获取审计日志类型
  async getAuditLogTypes() {
    let res;
    try {
      res = await getAuditLogTypes();
      if (res?.data && res?.code === 200) {
        runInAction(() => {
          this.auditLogTypes = res?.data;
        });
      }
    } catch (err) {}
  }

  //获取审计日志
  async getAuditLog(params) {
    runInAction(() => {
      this.tableLoading = true;
    });

    let res;

    try {
      res = await getAuditLog(params);
      if (res?.data && res.code === 200) {
        runInAction(() => {
          this.current = params.pageNum;
          this.pageSize = params.pageSize;
          this.auditLogList = res.data.operateLogList || [];
          this.total = res.data.total || 0;
        });
      }
    } catch (err) {}

    runInAction(() => {
      this.tableLoading = false;
    });
  }
}

export default new OperationHistory();
