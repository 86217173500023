import { makeAutoObservable, runInAction } from 'mobx';

import { collect, getMessageCount, getMessageList, getUserInfo, getUserInfoV1, messageRead } from '@/api';

class Overview {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  appsList = [];
  user = {};
  current = 1;
  pageSize = 6;
  total = 0;
  permissions = {};
  messageCount = 0;
  messageList = [];
  currentVersion = '';
  loginFirstCall = false;

  save(bol) {
    setTimeout(() => {
      this.loginFirstCall = bol;
    }, 300);
  }

  //获取用户信息、权限等，不需要访问权限，登录即可访问
  async getUserInfoV1() {
    let res;
    try {
      res = await getUserInfoV1();
      if (res?.data && res?.code === 200) {
        runInAction(() => {
          this.user = res.data?.user || {};
          this.permissions = {
            ...(res.data?.permissions || {}),
            page404: true
            // DASHBOARD_VIEW: false
            // APP_OVERVIEW: true,
            // COST_EXP_VIEW: true
          };
          this.currentVersion = res.data.currentVersion;
        });
      }
    } catch (err) {}

    return res;
  }

  //获取应用信息
  async getUserInfo({ pageNum = 1, pageSize = 6, searchKey = '', tagIds = [] }) {
    let res;
    const params = {
      pageNum,
      pageSize,
      searchKey,
      tagIds
    };

    try {
      res = await getUserInfo(params);
      if (res?.data && res?.code === 200) {
        runInAction(() => {
          this.current = pageNum;
          this.pageSize = pageSize;
          this.total = res.data.appTotal;
          this.appsList = res.data?.apps || [];
        });
      }
    } catch (err) {}

    return res;
  }

  async getMessageCount() {
    let res;
    try {
      res = await getMessageCount();
      if (res && res.code === 200) {
        runInAction(() => {
          this.messageCount = res.data || 0;
        });
      }
    } catch (err) {}
  }

  async getMessageList() {
    //state 0未读 1已读 2所有
    let res;
    const params = {
      state: 0
    };
    try {
      res = await getMessageList(params);
      if (res && res.data && res.code === 200) {
        runInAction(() => {
          this.messageList = res.data;
        });
      }
    } catch (err) {}
  }

  async messageRead(id) {
    let res;
    let params = {};
    if (id) {
      params.idList = [id];
    } else {
      params.idList = this.messageList.map(({ id }) => id);
    }

    try {
      res = await messageRead(params);
      if (res && res.code === 200) {
        this.getMessageCount();
        if (id) {
          runInAction(() => {
            this.messageList = this.messageList.map((item) => {
              if (id === item.id) {
                return {
                  ...item,
                  state: 1
                };
              }
              return { ...item };
            });
          });
        } else {
          runInAction(() => {
            this.messageList = this.messageList.map((item) => ({
              ...item,
              state: 1
            }));
          });
        }
      }
    } catch (err) {}
  }

  async collect(params) {
    let res;
    try {
      res = await collect(params);
    } catch (err) {}

    return res;
  }
}

export default new Overview();
