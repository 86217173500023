import { makeAutoObservable, runInAction } from 'mobx';

import { addRepositoryConfig, deleteRepositoryConfig, editRepositoryConfig, queryRepositoryConfigs } from '@/api';

class AppAdmin {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  tableLoading = false;
  tableList = [];

  //添加镜像仓库配置
  async addRepositoryConfig(params) {
    let res;
    try {
      res = await addRepositoryConfig(params);
    } catch (err) {}

    return res;
  }

  //删除镜像仓库配置
  async deleteRepositoryConfig(params) {
    let res;
    try {
      res = await deleteRepositoryConfig(params);
    } catch (err) {}

    return res;
  }

  //编辑镜像仓库配置
  async editRepositoryConfig(params) {
    let res;
    try {
      res = await editRepositoryConfig(params);
    } catch (err) {}

    return res;
  }

  //查询镜像仓库配置
  async queryRepositoryConfigs() {
    runInAction(() => {
      this.tableLoading = true;
    });

    let res;
    try {
      res = await queryRepositoryConfigs();
      if (res?.code === 200 && res?.data) {
        runInAction(() => {
          this.tableList = res.data.map((item) => ({ ...item, ...item.config }));
        });
      }
    } catch (err) {}

    runInAction(() => {
      this.tableLoading = false;
    });

    return this.tableList;
  }
}

export default new AppAdmin();
