import {makeAutoObservable, runInAction} from "mobx";
import {getChatUrl} from "../api";


class AiLab {

    constructor() {
        makeAutoObservable(this, {}, { autoBind: true });
    }

    chatUrl = '';

    async getChatUrl(cid) {
        let res;
        try {
            res = await getChatUrl(cid);
            runInAction(() => {
                if (res?.code === 200 && res?.data) {
                    this.chatUrl = res.data
                }
            });
        } catch (err) {

        }
    }
}

export default new AiLab();