import messageCenter from '@/store/MessageCenter';
import overview from '@/store/Overview';
import { getItem, parseTime } from '@/tools';
import { notification } from 'antd';
const envType = process.env['REACT_APP_ENV'];

export let socket = null;

let lockReconnect = false; //避免ws重复连接

export function createWebSocket(id) {
  if (!id) return;
  try {
    if ('WebSocket' in window) {
      //1.创建websocket
      socket = new WebSocket(`wss://${process.env[envType].url1?.match(/https:\/\/(.*)/)[1]}/websocket/${id}`);
    }
    initEventHandle();
  } catch (e) {
    // console.log('catch', e);
    heartCheck.reset();
    reconnect();
  }
}

export const initEventHandle = () => {
  //2.当和websocket服务连接成功的时候触发
  socket.addEventListener('open', () => {
    console.log('连接成功!');
    heartCheck.reset().start(); //心跳检测重置
  });

  //3.接收websocket服务的数据
  socket.addEventListener('message', (e) => {
    //如果获取到消息，心跳检测重置
    console.log('接收的数据：', JSON.parse(e.data));
    heartCheck.reset().start(); //拿到任何消息都说明当前连接是正常的

    try {
      if (e.data !== 'pong') {
        const data = JSON.parse(e.data);
        const { getMessageCount } = overview;
        const { changeFromCall } = messageCenter;
        getMessageCount();

        //在Message Center 页面，如果有通知消息，更新一下消息列表
        if (window.location.hash === '#/messageCenter') {
          changeFromCall(data?.id);
        }

        notification.open({
          message: <div className="multiline-ellipsis notification-ellipsis">{data?.title}</div>,
          description: parseTime(data.sendTime),
          duration: 5,
          top: 42
        });
      }
    } catch (err) {}
  });

  //4.断开连接
  socket.addEventListener('close', (e) => {
    console.log('断开连接!', e);
    heartCheck.reset();
    //wasClean值为false，异常断开
    if (!e.wasClean) {
      reconnect();
    }
  });

  //5.连接错误
  socket.addEventListener('error', () => {
    console.log('连接错误!');
    heartCheck.reset();
    reconnect();
  });
};

// 监听窗口关闭事件，当窗口关闭时，主动去关闭websocket连接，防止连接还没断开就关闭窗口，server端会抛异常。
window.onbeforeunload = function () {
  socket?.close();
};

//重新连接
function reconnect() {
  if (lockReconnect) return;
  lockReconnect = true;
  setTimeout(function () {
    //没连接上会一直重连，设置延迟避免请求过多
    const userId = getItem('userId');
    userId && createWebSocket(userId);
    lockReconnect = false;
  }, 2000);
}

//心跳检测
const heartCheck = {
  timeout: 10000, //10秒钟发一次心跳
  interval: null,
  reset: function () {
    clearInterval(this.interval);
    return this;
  },
  start: function () {
    this.interval = setInterval(function () {
      //这里发送一个心跳，后端收到后，返回一个心跳消息，
      //onmessage拿到返回的心跳就说明连接正常
      //socket.readyState 0(WebSocket.CONNECTING):正在链接中  1(WebSocket.OPEN):已经链接并且可以通讯  2(WebSocket.CLOSING):连接正在关闭  3(WebSocket.CLOSED):连接已关闭或者没有链接成功
      // console.log('ping!', socket.readyState);
      if (socket.readyState === 1) {
        socket.send('ping');
      }
    }, 10000);
  }
};
