import {
  createSecret,
  deleteConfigMap,
  deleteDeployment,
  deleteIngress,
  deletePod,
  deleteSecret,
  deleteService,
  editConfigMapData,
  editSecretData,
  getConfigMapData,
  getConfigMapList,
  getDeploymentDetail,
  getDeploymentList,
  getIngressList,
  getPodDetail,
  getPodList,
  getPodLog,
  getSecretData,
  getSecretList,
  getServiceAccountList,
  getServiceDetail,
  getServiceList,
  restartDeployment,
  rolloutDeployment,
  scaleDeployment
} from '@/api';

import { makeAutoObservable, runInAction } from 'mobx';

class Detail {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  detailList = [];
  // tableLoading = false;
  detailModalLoading = false;
  logModalLoading = false;
  logList = [];
  current = 1;
  pageSize = 50;
  podDetailData = {};
  deploymentDetailData = {};
  serviceDetailData = {};
  modalLoading = false;
  editData = [];
  allLogContentText = '';

  // get double() {
  //   return this.count * 2;
  // }

  savePagination(current, pageSize) {
    this.current = current;
    this.pageSize = pageSize;
  }

  //获取详情信息
  async getDetailList({ name, namespace, cluster, type }) {
    // runInAction(() => {
    //   this.tableLoading = true;
    // });

    const params = {
      namespace,
      cluster
    };

    // 1.Ingress config，Customize情况下，Secret下拉选择列表 2. CD config, Image Pull Secrets添加secret下拉列表
    if (type) {
      params['type'] = type;
    }

    let res;

    try {
      if (name === 'pod') {
        res = await getPodList(params);
      } else if (name === 'ingress') {
        res = await getIngressList(params);
      } else if (name === 'service') {
        res = await getServiceList(params);
      } else if (name === 'configmap') {
        res = await getConfigMapList(params);
      } else if (name === 'deployment') {
        res = await getDeploymentList(params);
      } else if (name === 'secret') {
        res = await getSecretList(params);
      } else if (name === 'serviceaccount') {
        res = await getServiceAccountList(params);
      }

      if (res && res.data) {
        runInAction(() => {
          this.detailList = res.data;
        });
      } else {
        runInAction(() => {
          this.detailList = [];
        });
      }
    } catch (err) {}

    // runInAction(() => {
    //   this.tableLoading = false;
    // });

    return this.detailList;
  }

  //获取detail modal信息
  async getDetailModalData(params, name) {
    runInAction(() => {
      this.detailModalLoading = true;
    });

    let res;
    try {
      if (name === 'pod') {
        res = await getPodDetail(params);
        if (res?.data) {
          runInAction(() => {
            this.podDetailData = res.data;
          });
        }
      } else if (name === 'deployment') {
        res = await getDeploymentDetail(params);
        if (res?.data) {
          runInAction(() => {
            this.deploymentDetailData = res.data;
          });
        }
      } else if (name === 'service') {
        res = await getServiceDetail(params);
        if (res?.data) {
          runInAction(() => {
            this.serviceDetailData = res.data;
          });
        }
      } else if (name === 'ingress') {
      }
    } catch (err) {}

    runInAction(() => {
      this.detailModalLoading = false;
    });
  }

  //获取log modal信息
  async getPodLog(params) {
    runInAction(() => {
      this.logModalLoading = true;
    });

    // this.savePagination(1, 50);

    let res;
    try {
      res = await getPodLog(params);
      if (res?.data) {
        runInAction(() => {
          this.allLogContentText = res.data;
        });
      }
    } catch (err) {}

    runInAction(() => {
      this.logModalLoading = false;
    });
  }

  //列表数据操作
  async listOperate({ name, namespace, cluster, amount, modalType, recordName, values }) {
    runInAction(() => {
      this.modalLoading = true;
    });

    let params = {
      cluster,
      namespace
    };

    let res;
    try {
      if (name === 'pod') {
        params['podName'] = recordName;
        if (modalType === 'delete') {
          res = await deletePod(params);
        }
      } else if (name === 'service') {
        params['serviceName'] = recordName;
        if (modalType === 'delete') {
          res = await deleteService(params);
        }
      } else if (name === 'deployment') {
        params['deploymentName'] = recordName;

        if (modalType === 'delete') {
          res = await deleteDeployment(params);
        } else if (modalType === 'scale') {
          params['amount'] = amount;
          res = await scaleDeployment(params);
        } else if (modalType === 'restart') {
          res = await restartDeployment(params);
        } else if (modalType === 'rollBack') {
          res = await rolloutDeployment(params);
        }
      } else if (name === 'configmap') {
        params['configMapName'] = recordName;
        if (modalType === 'delete') {
          res = await deleteConfigMap(params);
        } else if (modalType === 'edit') {
          params['data'] = values;
          res = await editConfigMapData(params);
        }
      } else if (name === 'secret') {
        params['secretName'] = recordName;
        if (modalType === 'delete') {
          res = await deleteSecret(params);
        } else if (modalType === 'edit') {
          params['data'] = values?.secrets?.reduce((obj, item) => {
            obj[item.name] = item.value;
            return obj;
          }, {});
          res = await editSecretData(params);
        }
      } else if (name === 'ingress') {
        params['ingress'] = recordName;
        if (modalType === 'delete') {
          res = await deleteIngress(params);
        }
      }
    } catch (err) {}

    runInAction(() => {
      this.modalLoading = false;
    });

    return res;
  }

  //获取编辑数据
  async getEditData({ name, namespace, cluster, recordName }) {
    let params = {
      cluster,
      namespace
    };
    let res;

    try {
      if (name === 'configmap') {
        params['configMapName'] = recordName;
        res = await getConfigMapData(params);
      } else if (name === 'secret') {
        params['secretName'] = recordName;
        res = await getSecretData(params);
      }
    } catch (err) {}

    const list = Object.keys(res?.data || {}).map((item) => ({
      name: item,
      value: res.data[item]
    }));

    runInAction(() => {
      this.editData = list;
    });

    return list;
  }

  //创建 secret
  async createSecret(params) {
    let res;
    try {
      res = await createSecret(params);
    } catch (err) {}

    return res;
  }
}

export default new Detail();
