import { makeAutoObservable, runInAction } from 'mobx';

import { deleteMessage, getMessageList, messageRead } from '@/api';

class MessageCenter {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  allMessageList = [];
  tableLoading = false;
  fromCall = '';

  changeFromCall(id) {
    this.fromCall = id;
  }

  //获取通知列表
  async getMessageList() {
    runInAction(() => {
      this.tableLoading = true;
    });
    //state 0未读 1已读 2所有
    let res;
    const params = {
      state: 2,
    };
    try {
      res = await getMessageList(params);
      if (res && res.data && res.code === 200) {
        runInAction(() => {
          this.allMessageList = res.data;
        });
      }
    } catch (err) {}

    runInAction(() => {
      this.tableLoading = false;
    });

    return this.allMessageList;
  }

  async messageRead() {
    let res;
    let params = {};
    params.idList = this.allMessageList
      .filter((item) => item.state === 0)
      .map(({ id }) => id);

    try {
      res = await messageRead(params);
    } catch (err) {}

    return res;
  }

  async deleteMessage(selectedRowKeys) {
    let res;
    try {
      res = await deleteMessage({ idList: selectedRowKeys });
    } catch (err) {}

    return res;
  }
}

export default new MessageCenter();
