import { scrollTo } from '@/defineHooks/useScroll';
// import { createWebSocket, socket } from '@/socket';
import useStore from '@/store';
import { C, filterAuthorizedRoutes, getItem } from '@/tools';
import { ConfigProvider } from 'antd';
import enUS from 'antd/es/locale/en_US';
import zhCN from 'antd/es/locale/zh_CN';
import _ from 'lodash';
import { observer } from 'mobx-react';
import { useEffect, useLayoutEffect, useState } from 'react';
import { HashRouter, useLocation, useRoutes } from 'react-router-dom';
import './App.less';
import ErrorBoundary from './ErrorBoundary';
import './i18n/config';
import { router } from './route';
import gdp from 'gio-webjs-sdk-cdp'

window._ = _;
const qs = require('query-string');

function UseRoutesCom({ routes }) {
  //处理路由拦截
  const location = useLocation();
  // const navigate = useNavigate();
  // const obj = qs.parse(location.search);

  const {
    overview: { getUserInfoV1, getMessageCount }
  } = useStore();

  useEffect(() => {
    //1.在除了首页的其他路由下，刷新页面用户名会置为空，需要重新获取
    //2.ppn登录时，由于先发请求后保存的token，会跳转登录页面，需要加判断
    if (getItem('token')) {
      getUserInfoV1();
      getMessageCount();
    }

    //1.token 存在说明用户在登录状态 2.socket不存在说明目前处于断开连接 3.排除登录页面
    // if (getItem('token') && !socket && location.pathname !== '/login') {
    //   const userId = getItem('userId');
    //   // console.log('userId=====', userId);
    //   createWebSocket(userId);
    // }
  }, []);

  useEffect(() => {
    scrollTo();
  }, [location.pathname]);

  return <div>{useRoutes(routes)}</div>;
}

const App = () => {
  const lang = getItem('lang');
  const [locale, setLocale] = useState(lang || 'en');


  const [routes, setRoutes] = useState([]);
  const {
    overview: { permissions }
  } = useStore();

  useEffect(() => {
    //过滤出1.有authority属性且属性值在权限对象中为true 2. 没有authority属性的路由
    const filterRoutes = filterAuthorizedRoutes(router, permissions);
    setRoutes(filterRoutes);
  }, [permissions, router]);

  useLayoutEffect(() => {
    window._gr_ignore_local_rule = true;
    gdp('init', "858f0e6e9b582a3b", "b9b11bd36d12e0d1", {
      host: "https://collect.gio.porsche.cn",
      debug: false,
      compress: false,
      scheme: 'https',
      storageType: 'localStorage',
      version: "1.0.0",
    })
  }, []);


  return (
    <div className="App">
      <C.Provider value={{ locale, setLocale }}>
        <ConfigProvider locale={locale === 'zh' ? zhCN : enUS}>
          <ErrorBoundary>
            <HashRouter>
              <UseRoutesCom routes={routes} />
            </HashRouter>
          </ErrorBoundary>
        </ConfigProvider>
      </C.Provider>
    </div>
  );
};

export default observer(App);
