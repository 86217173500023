import {makeAutoObservable, runInAction} from "mobx";
import {
    reviewMeshGeneratedConfig,
    updateMeshConfig,
    openPromotionGate,
    openRollbackGate,
    removeCanary,
    getMeshStatus
} from '@/api';

class MeshStore {

    constructor() {
        makeAutoObservable(this, {}, {autoBind: true});
    }

    abUrlMatch = 'prefix';
    abUrlPath = '/';
    abHeaders = [{
        'match': 'exact',
        'key': 'header',
        'value': 'value'
    }];
    timeout = 60

    appId = '';
    serverName = '';

    setMeshParams(appId, serverName, record){
        this.appId = appId
        this.serverName = serverName

        if (record) {
            runInAction(() => {
                this.abUrlMatch = record.match;
                this.abUrlPath = record.uri;
                this.abHeaders = record.headers;
                this.timeout = record.timeout;
            });
        }
    }

    async reviewMeshGeneratedConfig(params) {
        let res;
        try {
            // using current appId and service
            const appId = this.appId;
            const serverName = this.serverName;
            res = await reviewMeshGeneratedConfig({appId, serverName,
                uri: {path: params.uri, match: params.match},
                headers: params.headers,
                timeout: params.timeout
            });
            if (res && res.code === 200 && res.data) {
                return res.data;
            }
        } catch (err) {}
        return '';
    }

    async updateMeshConfig(params) {
        let res;
        try {
            // using current appId and service
            const appId = this.appId;
            const serverName = this.serverName;
            res = await updateMeshConfig({appId, serverName,
                uri: {path: params.uri, match: params.match},
                headers: params.headers,
                timeout: params.timeout
            });
        } catch (err) {}
        return res
    }

    async removeCanary(appId, serverName) {
        let res;
        try {
            res = await removeCanary({
                'id': appId,
                'serverName': serverName
            });
        } catch (err) {}
        return res
    }

    async promoteCanary(appId, serverName) {
        return await openPromotionGate(appId, serverName);
    }

    async rollbackCanary(appId, serverName) {
        return await openRollbackGate(appId, serverName);
    }

    async getMeshStatus() {
        try {
            const res = await getMeshStatus(this.appId, this.serverName);
            if (res && res.code === 200 && res.data) {
                return res.data;
            }
        } catch (err) {}
        return null;
    }
}

export default new MeshStore();