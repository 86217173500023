import { makeAutoObservable, runInAction } from 'mobx';

import { getUserInfoV1 } from '@/api';

class PersonalInfo {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  infoData = {};

  //获取环境信息
  async getUserInfoV1() {
    let res;
    try {
      res = await getUserInfoV1();
      if (res?.data && res?.code === 200) {
        runInAction(() => {
          this.infoData = res.data;
        });
      }
    } catch (err) {}

    return res;
  }
}

export default new PersonalInfo();
