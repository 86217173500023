import { makeAutoObservable, runInAction } from 'mobx';

import { getPermissionList, getRoleList } from '@/api';

class RoleAdmin {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  roleList = [];
  selectDisabled = false;
  permissionList = [];

  clearRoleList() {
    this.roleList = [];
  }

  //获取角色列表数据
  async getRoleList() {
    let res;
    try {
      res = await getRoleList();
      if (res?.code === 200 && res.data) {
        runInAction(() => {
          this.roleList = res.data;
        });
      }
    } catch (err) {}

    return res;
  }

  //获取权限列表数据
  async getPermissionList(id) {
    runInAction(() => {
      this.selectDisabled = true;
    });
    let res;
    try {
      res = await getPermissionList({ roleId: id });
      if (res?.code === 200 && res.data) {
        runInAction(() => {
          this.permissionList = res.data;
        });
      }
    } catch (err) {}

    runInAction(() => {
      this.selectDisabled = false;
    });

    return res;
  }
}

export default new RoleAdmin();
