import { makeAutoObservable, runInAction } from 'mobx';

import {
  argocdDeleteDeploy,
  argocdDeleteIngress,
  argocdDeployIngress,
  argocdDeployment,
  argocdEditDeploy,
  argocdEditIngress,
  cdRollback,
  editQuota,
  getAppCDInfo,
  getAppCDStatus,
  getCDEditInfo,
  getCDReview,
  getHostUrl,
  getInfoDetail,
  getIngressCDInfo,
  getIngressCDReview,
  getIngressCDStatus,
  getCronJobList,
  getCronjobReview,
  argocdDeployCronjob,
  getCronjobCDStatus,
  markTag,
  deleteCronjob
} from '@/api';
import {argocdEditCronJob} from "../api";

class InfoDetail {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  general = {};
  detail = [];
  pageLoading = false;
  CDInfoList = [];
  CDListLoading = false;
  CDStatusList = [];
  ingressStatusList = [];
  cronJobStatusList = [];
  ingressList = [];
  IngressListLoading = false;
  hostUrl = '';
  cronJobLoading = false;
  cronJobList = [];
  cronJobItem = {cronJobName: '',
      image: '',
      tag: '',
      scheduleType: '2',
      schedule: '',
      command: ''};

  //获取应用详情信息
  async getInfoDetail(id) {
    runInAction(() => {
      this.pageLoading = true;
    });
    const params = {
      id
    };
    let res;
    try {
      res = await getInfoDetail(params);
      if (res && res.data) {
        runInAction(() => {
          this.general = res.data.general || {};
          this.detail = res.data.detail || [];
        });
      }
    } catch (err) {}

    this.pageLoading = false;
  }

  async editQuota(params) {
    let res;
    try {
      res = await editQuota(params);
    } catch (err) {}

    return res;
  }

  async markTag(params) {
    let res;
    try {
      res = await markTag(params);
    } catch (err) {}

    return res;
  }

  async getAppCDInfo(id, hand) {
    if (hand) {
      runInAction(() => {
        this.CDListLoading = true;
      });
    }

    const params = {
      id
    };
    let res;
    try {
      res = await getAppCDInfo(params);
      if (res?.code === 200 && res?.data) {
        runInAction(() => {
          this.CDInfoList = res.data || [];
        });
      }
    } catch (err) {}

    if (hand) {
      runInAction(() => {
        this.CDListLoading = false;
      });
    }

    return res;
  }

  async getCDEditInfo(params) {
    let res;
    try {
      res = await getCDEditInfo(params);
    } catch (err) {}

    return res;
  }

  async getIngressCDInfo(id, hand) {
    if (hand) {
      runInAction(() => {
        this.IngressListLoading = true;
      });
    }

    const params = {
      id
    };
    let res;
    try {
      res = await getIngressCDInfo(params);
      if (res?.code === 200 && res?.data) {
        runInAction(() => {
          this.ingressList = res.data.filter((item) => item.kind) || [];
        });
      }
    } catch (err) {}

    if (hand) {
      runInAction(() => {
        this.IngressListLoading = false;
      });
    }

    return res;
  }

  convertCronjobParams(params) {

    if (params.scheduleType === '1')
      params.schedule = '* * * * *'
    else if (params.scheduleType === '2')
      params.schedule = '0 * * * *'
    else if (params.scheduleType === '3')
      params.schedule = '0 0 * * *'
    else if (params.scheduleType === '4')
      params.schedule = '0 0 * * 0'
    else if (params.scheduleType === '5')
      params.schedule = this.cronJobItem.schedule
    const { expression, ...converted_params } = params;
    return converted_params
  }

  async setCronJobItem(cronJob) {
    runInAction(() => this.cronJobItem = cronJob)
  }

  async getCronjobReview(params) {
    let res;
    try {
      const converted_params = this.convertCronjobParams(params)
      res = await getCronjobReview(converted_params);
    } catch (err) {}

    return res;
  }

  async deleteCronjob(params) {
    let res;
    try {
      res = await deleteCronjob(params);
    } catch (err) {}

    return res;
  }

  async argocdDeployCronjob(params) {
    let res;
    try {
      const converted_params = this.convertCronjobParams(params)
      res = await argocdDeployCronjob(converted_params);
    } catch (err) {}

    return res;
  }

  async argocdEditCronJob(params) {
    let res;
    try {
      const converted_params = this.convertCronjobParams(params)
      res = await argocdEditCronJob(converted_params);
    } catch (err) {}

    return res;
  }

  async getCronjobCDStatus(params) {
    let res;
    try {
      res = await getCronjobCDStatus(params);
      if (res?.code === 200 && res?.data) {
        this.cronJobStatusList = res.data;
      }
    } catch (err) {}

    return res;
  }

  async getCDReview(params) {
    let res;
    try {
      res = await getCDReview(params);
    } catch (err) {}

    return res;
  }

  async getIngressCDReview(params) {
    let res;
    try {
      res = await getIngressCDReview(params);
    } catch (err) {}

    return res;
  }

  async argocdDeployment(params) {
    let res;
    try {
      res = await argocdDeployment(params);
    } catch (err) {}

    return res;
  }

  async argocdDeployIngress(params) {
    let res;
    try {
      res = await argocdDeployIngress(params);
    } catch (err) {}

    return res;
  }

  async getAppCDStatus(params) {
    let res;
    try {
      res = await getAppCDStatus(params);
      if (res?.code === 200 && res?.data) {
        this.CDStatusList = res.data;
      }
    } catch (err) {}

    return res;
  }

  async getIngressCDStatus(params) {
    let res;
    try {
      res = await getIngressCDStatus(params);
      if (res?.code === 200 && res?.data) {
        this.ingressStatusList = res.data;
      }
    } catch (err) {}

    return res;
  }

  async getCronJobList(id, hand=false){
    runInAction(() => {
      if (hand)
        this.cronJobLoading = true;
    })

    const params = {
      id
    };

    let res;
    try {
      res = await getCronJobList(params);
      if (res?.code === 200 && res?.data) {
        this.cronJobList = res.data;
      }
    } catch (err) {}

    runInAction(() => {
      if (hand)
        this.cronJobLoading = false;
    })
    return res;
  }

  async argocdDeleteDeploy(params) {
    let res;
    try {
      res = await argocdDeleteDeploy(params);
    } catch (err) {}

    return res;
  }

  async argocdDeleteIngress(params) {
    let res;
    try {
      res = await argocdDeleteIngress(params);
    } catch (err) {}

    return res;
  }

  async argocdEditDeploy(params) {
    let res;
    try {
      res = await argocdEditDeploy(params);
    } catch (err) {}

    return res;
  }

  async argocdEditIngress(params) {
    let res;
    try {
      res = await argocdEditIngress(params);
    } catch (err) {}

    return res;
  }

  async getHostUrl(cluster) {
    let res;
    try {
      res = await getHostUrl({ cluster });
      if (res?.data && res?.code === 200) {
        this.hostUrl = res.data.match(/^https:\/\/([^/]+)/)?.[1];
      }
    } catch (err) {}
  }

  async cdRollback(params) {
    let res;
    try {
      res = await cdRollback(params);
    } catch (err) {}

    return res;
  }
}

export default new InfoDetail();
