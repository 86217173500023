import { makeAutoObservable, runInAction } from 'mobx';

import { login } from '../api';

class Login {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  loginLoading = false;

  //登录
  async userLogin(values) {
    runInAction(() => {
      this.loginLoading = true;
    });
    const params = {
      ...values
    };
    let res;
    try {
      res = await login(params);
    } catch (err) {}

    runInAction(() => {
      this.loginLoading = false;
    });

    return res;
  }
}

export default new Login();
