import { makeAutoObservable } from 'mobx';

import { modifyPassword } from '@/api';

class ChangePassword {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  //修改用户密码
  async modifyPassword(params) {
    let res;
    try {
      res = await modifyPassword(params);
    } catch (err) {}

    return res;
  }
}

export default new ChangePassword();
