import { makeAutoObservable, runInAction } from 'mobx';

import {
  addApp,
  deleteApp,
  editApp,
  getApp,
  getAppDetail,
  getEnvList,
  getUserList,
} from '@/api';

class AppAdmin {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  envData = [];
  userData = [];
  appsList = [];
  selectDisabled = false;
  detailData = {};

  clearAppsList() {
    this.appsList = [];
  }

  //获取应用列表数据
  async getApp() {
    let res;
    try {
      res = await getApp();
      if (res && res.code === 200 && res.data) {
        runInAction(() => {
          this.appsList = res.data;
        });
      }
    } catch (err) {}

    return res;
  }

  //获取应用详情（关联环境及用户）
  async getAppDetail(id) {
    runInAction(() => {
      this.selectDisabled = true;
    });
    let res;
    try {
      res = await getAppDetail({ appId: id });
      if (res && res.code === 200 && res.data) {
        runInAction(() => {
          this.detailData = res.data;
        });
      }
    } catch (err) {}

    runInAction(() => {
      this.selectDisabled = false;
    });

    return res;
  }

  //编辑应用
  async editApp(params) {
    let res;
    try {
      res = await editApp(params);
    } catch (err) {}

    return res;
  }

  //删除应用
  async deleteApp(appId) {
    let res;
    try {
      res = await deleteApp({ appId });
    } catch (err) {}

    return res;
  }

  //获取环境信息
  async getEnvList() {
    let res;
    try {
      res = await getEnvList();
      if (res?.data && res?.code === 200) {
        runInAction(() => {
          this.envData = res.data;
        });
      }
    } catch (err) {}

    return res;
  }

  //获取用户信息
  async getUserList() {
    let res;
    try {
      res = await getUserList();
      if (res?.data && res?.code === 200) {
        runInAction(() => {
          this.userData = res.data;
        });
      }
    } catch (err) {}

    return res;
  }

  //新增应用
  async addApp(params) {
    let res;
    try {
      res = await addApp(params);
    } catch (err) {}

    return res;
  }
}

export default new AppAdmin();
