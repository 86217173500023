import { Result } from 'antd';
import React from 'react';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: '' };
  }

  static getDerivedStateFromError(error) {
    // 更新 state 使下一次渲染能够显示降级后的 UI
    console.log('error', error, '====', String(error));
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    // 你同样可以将错误日志上报给服务器
    // console.log(
    //   "22222222",
    //   error === this.state.error,
    //   "==",
    //   error,
    //   "==",
    //   String(error),
    //   "==",
    //   errorInfo
    // );
  }

  render() {
    // console.log("3333333", this.state);
    const { error } = this.state;
    if (String(error).includes('Loading chunk')) {
      //   console.log("66666666");
      return window.location.reload();
    }

    if (this.state.hasError) {
      // 你可以自定义降级后的 UI 并渲染
      return (
        <Result
          status="500"
          // title="500"
          subTitle="Sorry, something went wrong."
          // extra={<Button type="primary">Back Home</Button>}
        />
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
