import { makeAutoObservable, runInAction } from 'mobx';

import {
  editGrafanaConfig,
  getAppList,
  getAppMonitorDetail,
  getBlockList,
  getBlockMetrics} from '@/api';

class Monitor {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  appsList = [];
  monitorDetail = {
    rateList: [],
    metricsList: [],
    chartLinkDto: {},
  };
  blockList = []; // a dictionary, key is block id and values is metrics list
  selectDisabled = false;

  // show loading for blocking
  blockLoading = false;

  clearAppsList() {
    this.appsList = [];
  }

  //获取app列表
  async getAppList() {
    let res;
    try {
      res = await getAppList();
      if (res && res.code === 200 && res.data) {
        runInAction(() => {
          this.appsList = res.data || [];
        });
      }
    } catch (err) {}

    return res;
  }

  //获取监控详情信息
  async getAppMonitorDetail(id) {
    runInAction(() => {
      this.selectDisabled = true;
    });
    let res;
    try {
      res = await getAppMonitorDetail({ id });
      if (res && res.code === 200 && res.data) {
        runInAction(() => {
          this.monitorDetail = {
            rateList: res.data.rateList || [],
            metricsList: res.data.metricsList || [],
            chartLinkDto: res.data.chartLinkDto || {},
          };
        });
      }
    } catch (err) {}

    runInAction(() => {
      this.selectDisabled = false;
    });

    return res;
  }

  async editGrafanaConfig(params) {
    let res;
    try {
      res = await editGrafanaConfig(params);
    } catch (err) {}

    return res;
  }

  async getBlockList(appId, timeRange) {
    runInAction(() => {
      this.blockLoading = true;
      this.blockList = [];
    });
    try {
      const blocks = await getBlockList({'id': appId});
      let blockList = [];
      // for each blockId, load metricsId
      for (const block of blocks.data) {
        const metrics = await this.getBlockMetrics(block.id, timeRange);
        blockList.push({'id': block.id, 'name': block.name, 'metrics': metrics.metrics})
      }
      runInAction(() => {
        this.blockList = blockList
      })
    } catch (err) {}

    runInAction(() => {
      this.blockLoading = false;
    });
  }

  async getBlockMetrics(blockId, timeRange) {
    let res;
    try {
      res = await getBlockMetrics({'blockId': blockId, 'timeRange': timeRange});
    } catch (err) {}
    return res.data;
  }
}

export default new Monitor();
