import { makeAutoObservable, runInAction } from 'mobx';
import {
    getEsLoggingList,
    saveEsLogging,
    deleteLogging,
    saveSplunkLogging,
    getSplunkLoggingList,
    getLoggingCount
} from "../api";

class LoggingStore {
    constructor() {
        makeAutoObservable(this, {}, { autoBind: true });
    }

    esLoggingLoading = false;
    esLoggingList = [];

    splunkLoggingList = [];
    splunkLoading = false;

    esCount = 0;
    splunkCount = 0;

    async getLoggingCount(params) {
        let res;
        try {
            res = await getLoggingCount(params);
            if (res?.code === 200 && res?.data) {
                runInAction(() => {
                    this.esCount = res.data?.esCount;
                    this.splunkCount = res.data?.splunkCount;
                });
            }
        } catch (err) {}
    }

    async getEsLoggingList(params) {
        runInAction(() => (this.esLoggingLoading = true));

        let res;
        try {
            res = await getEsLoggingList(params);
            if (res?.code === 200 && res?.data) {
                runInAction(() => {
                    this.esLoggingList = res.data;
                });
            }
        } catch (err) {}

        runInAction(() => (this.esLoggingLoading = false));
    }

    async saveEsLogging(config) {
        let res;
        try {
            res = await saveEsLogging(config);
        } catch (err) {}
        return res;
    }

    async deleteLogging(params) {
        let res;
        try {
            res = await deleteLogging(params);
        } catch (err) {}
        return res;
    }

    async getSplunkLoggingList(params) {
        runInAction(() => (this.splunkLoading = true));

        let res;
        try {
            res = await getSplunkLoggingList(params);
            if (res?.code === 200 && res?.data) {
                runInAction(() => {
                    this.splunkLoggingList = res.data;
                });
            }
        } catch (err) {
        }

        runInAction(() => (this.splunkLoading = false));
    }

    async saveSplunkLogging(config) {
        let res;
        try {
            res = await saveSplunkLogging(config);
        } catch (err) {}
        return res;
    }
}

export default new LoggingStore();
