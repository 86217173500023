import { message } from 'antd';
import axios from 'axios';
import {getItem} from "../tools";

//获取环境变量 START || DEV || STAG || PROD
const envType = process.env['REACT_APP_ENV'];

const instance = axios.create({
  baseURL: process.env['NODE_ENV'] === 'production' ? process.env[envType].url2 : ''
});

//请求拦截
instance.interceptors.request.use(
  (config) => {
      const token = getItem('token');
      config.headers['token'] = token;
      return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

//响应拦截
instance.interceptors.response.use(
  (res) => {
    if (res.status >= 200 && res.status < 300) {
      return res.data;
    }
    return Promise.reject(res.statusText);
  },
  (error) => {
    if ('message' in error) {
      message.error(error.message);
    }

    return Promise.reject(error);
  }
);

export default instance;
