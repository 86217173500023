import { makeAutoObservable, runInAction } from 'mobx';

import { addSuggest, getSuggestList, querySuggestList } from '@/api';

class Feedback {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  tableLoading = false;
  myFeedbackList = [];
  allFeedbackList = [];
  fromCall = '';

  changeFromCall(from) {
    this.fromCall = from;
  }

  async addSuggest(params) {
    let res;
    try {
      res = await addSuggest(params);
    } catch (err) {}

    return res;
  }

  async querySuggestList() {
    runInAction(() => (this.tableLoading = true));
    let res;
    try {
      res = await querySuggestList();
      if (res?.code === 200 && res?.data) {
        runInAction(() => {
          this.myFeedbackList = res.data;
        });
      }
    } catch (err) {}

    runInAction(() => (this.tableLoading = false));
    return this.myFeedbackList;
  }

  async getSuggestList(params) {
    runInAction(() => (this.tableLoading = true));
    let res;
    try {
      res = await getSuggestList(params);
      if (res?.code === 200 && res?.data) {
        runInAction(() => {
          this.allFeedbackList = res.data;
        });
      }
    } catch (err) {}

    runInAction(() => (this.tableLoading = false));
  }
}

export default new Feedback();
