import { makeAutoObservable, runInAction } from 'mobx';

import { getUserInfo } from '@/api';

class Favorite {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  current = 1;
  pageSize = 6;
  total = 0;
  favoriteList = [];
  favoriteLoading = false;

  //获取用户信息
  async getUserInfo({ pageNum = 1, pageSize = 6, searchKey = '', tagIds = [] }) {
    runInAction(() => {
      this.favoriteLoading = true;
    });

    let res;
    const params = {
      pageNum,
      pageSize,
      searchKey,
      tagIds,
      favorite: true
    };

    try {
      res = await getUserInfo(params);
      if (res?.code === 200 && res?.data) {
        runInAction(() => {
          this.current = pageNum;
          this.pageSize = pageSize;
          this.total = res.data.appTotal;
          this.favoriteList = res.data?.apps || [];
        });
      }
    } catch (err) {}

    runInAction(() => {
      this.favoriteLoading = false;
    });

    return res;
  }
}

export default new Favorite();
