import { makeAutoObservable, runInAction } from 'mobx';

import { addTag, deleteTag, getTagList } from '@/api';

class TagAdmin {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  tagList = [];
  tableLoading = false;

  async getTagList() {
    runInAction(() => (this.tableLoading = true));
    let res;
    try {
      res = await getTagList();
      if (res?.code === 200 && res?.data) {
        runInAction(() => {
          this.tagList = res.data;
        });
      }
    } catch (err) {}

    runInAction(() => (this.tableLoading = false));

    return res;
  }

  async addTag(params) {
    let res;
    try {
      res = await addTag(params);
    } catch (err) {}

    return res;
  }

  async deleteTag(ids) {
    let res;
    try {
      res = await deleteTag({ tagIds: ids });
    } catch (err) {}

    return res;
  }
}

export default new TagAdmin();
