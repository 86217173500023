import { makeAutoObservable, runInAction } from 'mobx';

import { getClusters, getNamespace, getPodList, getPodLog } from '@/api';

class LogCenter {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  clusterList = [];
  namespaceList = [];
  podNameList = [];
  logList = [];
  tableLoading = false;
  nameSpaceLoading = false;
  podNameLoading = false;
  current = 1;
  pageSize = 50;
  allLogContentText = '';

  savePagination(current, pageSize) {
    this.current = current;
    this.pageSize = pageSize;
  }

  async getClusters() {
    let res;
    try {
      res = await getClusters();
      if (res?.code === 200 && res?.data) {
        runInAction(() => {
          this.clusterList = res.data || [];
        });
      }
    } catch (err) {}

    return this.clusterList;
  }

  //获取日志信息
  async getPodLog(values) {
    // console.log("values", values);
    runInAction(() => {
      this.tableLoading = true;
    });

    let res;
    try {
      res = await getPodLog(values);
      if (res?.data) {
        runInAction(() => {
          this.allLogContentText = res.data;
        });
      }
    } catch (err) {}

    runInAction(() => {
      this.tableLoading = false;
    });

    return this.allLogContentText;
  }

  //获取应用列表数据(namespace)
  async getNamespace(cluster) {
    runInAction(() => {
      this.nameSpaceLoading = true;
    });
    let res;
    try {
      res = await getNamespace({ cluster });
      if (res?.data) {
        runInAction(() => {
          this.namespaceList = res.data;
        });
      }
    } catch (err) {}

    runInAction(() => {
      this.nameSpaceLoading = false;
    });

    return this.namespaceList;
  }

  //获取podName列表数据
  async getPodNameList(cluster, namespace) {
    runInAction(() => {
      this.podNameLoading = true;
    });
    let res;
    try {
      res = await getPodList({ cluster, namespace });
      if (res?.data) {
        runInAction(() => {
          this.podNameList = res.data;
        });
      }
    } catch (err) {}

    runInAction(() => {
      this.podNameLoading = false;
    });

    return this.podNameList;
  }
}

export default new LogCenter();
